<template>
  <BaseLayout>
    <SuspenseLoader :isLoaded="isLoaded" v-if="$acl.canView('reports')">
      <ReportForVehicleScreen
        v-if="shouldDisplayA"
        :key="`report-details-ui`"
        :details="reportDetails"
        @change="updateData($event)"
      />

      <ReportForPaymentScreen
        v-else-if="shouldDisplayB"
        :key="`report-details-ui`"
        :details="reportDetails"
        @change="updateData($event)"
      />

      <div v-else :key="`report-details-ui`">
        Report category not found!
      </div>
    </SuspenseLoader>
    <div v-else class="py-5 font-bold text-center text-gray-600">
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
  </BaseLayout>
</template>

<script>
export default {
  name: 'ReportDetails',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout'),
    ReportForVehicleScreen: () =>
      import('@/composites/report/details/ReportForVehicleScreen'),
    ReportForPaymentScreen: () =>
      import('@/composites/report/details/ReportForPaymentScreen'),
  },
  data: () => ({
    isLoading: false,
    isLoaded: false,
    reportDetails: null,
    reportCategory: null,
    // todo: move to util / config file
    reportCategories: {
      'Damaged Vehicle': 'DAMAGED_VEHICLE',
      'Wrongly Parked': 'WRONGLY_PARKED',
      'Need Maintenance': 'NEED_MAINTENANCE',
      'Missing': 'MISSING',
      'Out Of Service Area': 'OUT_OF_SERVICE_AREA',
      'Restricted Area': 'RESTRICTED_AREA',
      'Other': 'OTHER',
    },
  }),
  computed: {
    id() {
      return this.$route.params.id
    },
    shouldDisplayA() {
      const aWhitelist = ['Damaged Vehicle', 'Wrongly Parked', 'App Experience']

      return aWhitelist.includes(this.reportCategory)
    },
    shouldDisplayB() {
      const bWhitelist = [
        'Need Maintenance',
        'Missing',
        'Out Of Service Area',
        'Restricted Area',
        'Other',
      ]

      return bWhitelist.includes(this.reportCategory)
    },
    view() {
      return this.$route.query.view
    },
  },
  async created() {
    if (this.$acl.canView('reports')) {
      this.isLoading = true
      await this.$http
        .get(this.$config.report.api.single(this.id))
        .then((res) => {
          // console.log('report-details', res)

          this.reportDetails = res.data
          this.reportCategory = res.data.report_category

          this.isLoaded = true
        })
        .catch((err) => {
          console.log('report-details-err', err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }
  },
  methods: {
    updateData(e) {
      console.log(this.reportDetails)
      this.reportDetails = e
      console.log(this.reportDetails)
    },
  },
}
</script>

<style></style>
